import {
  api,
  getTenant,
  getUserId,
  getUserName,
} from '@iblai/ibl-web-react-common';

export function getCourseInfo(courseID, callback, errorCB = null) {
  api.ibledxcourses.getCourseMeta(
    { course_key: encodeURI(courseID) },
    function (data) {
      callback(data);
    },
    function (error) {
      errorCB && errorCB(error);
    }
  );
}

export function getCourseDetails(courseID, callback) {
  const data = {
    course_id: courseID,
  };

  api.ibledxcourses.getCourseOutline(data, (_data) => {
    callback(_data);
  });
}

export function getEnrollmentStatus(courseID, callback) {
  let data = {
    course_id: courseID,
  };
  if (
    process.env.REACT_APP_ENABLE_COURSE_ELIGIBILITY_LICENSE_CHECK === 'true'
  ) {
    // data['course_id'] = encodeURIComponent(data['course_id']);
    data['user_id'] = getUserId();
    data['username'] = getUserName();
    api.ibldmcourses.fetchEnrollStatusWithEligibilityCheck(data, (data) => {
      callback(data);
    });
  } else {
    api.ibledxcourses.fetchEnrollStatus(data, function (data) {
      callback(data);
    });
  }
}

export function enrollToCourse(callback, courseID) {
  let data = {
    course_id: courseID,
  };
  api.ibledxcourses.enrollToCourse(data, function (data) {
    callback(data);
  });
}

export function selfEnrollToCourse(callback, errorCallback, courseID) {
  api.ibledxcourses.selfEnrollToCourse(
    { course_id: courseID },
    callback,
    errorCallback
  );
}

export function requestAccessToCourse(
  callback,
  errorCallback,
  courseID,
  platformKey
) {
  let data = {
    course_id: courseID,
    platform_key: platformKey,
  };
  api.ibledxcourses.requestAccessToCourse(
    data,
    function (data) {
      callback(data);
    },
    (error) => {
      errorCallback(error);
    }
  );
}

export function getStripeProduct(courseID, callback) {
  api.ibldmstripe.getStripeProducts(
    {
      username: getUserName(),
      org: getTenant(),
    },
    (products) => {
      callback(products.find((product) => product.sku === courseID));
    },
    (error) => {
      callback(null);
    }
  );
}

export function getAllStripeProducts(courseName, courseID) {
  return new Promise((resolve, reject) => {
    let data = {
      callback: 'fetchAllProducts',
    };
    api.iblwebstripe.getFromStripe(data, function (data) {
      let courses = data.data;
      let hasProduct = false;
      for (let i = 0; i < courses.length; i++) {
        if (
          c_slugify(courseName) === c_slugify(courses[i].name) &&
          courses[i].active
        ) {
          hasProduct = true;
          checkStripeProductPrice(courses[i].id, courseID)
            .then((productData) => resolve(productData))
            .catch((error) => reject(error));
          break;
        }
      }
      if (!hasProduct) {
        reject({ message: 'Product not found' });
      }
    });
  });
}

function c_slugify(Text) {
  return Text.toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
}

function checkStripeProductPrice(product_id, course_id) {
  return new Promise((resolve, reject) => {
    let data = {
      product_id: product_id,
      callback: 'fetchProductPrice',
    };

    api.iblwebstripe.getFromStripe(data, function (data) {
      let price_id = '';
      let type = '';
      let amount = '';
      let mode = '';
      let productData = {};
      data.data.forEach((price) => {
        if (!price.active) {
          return;
        }
        if (price.active) {
          price_id = price.id;
          type = price.type;
          amount = price.unit_amount;
          productData['price_id'] = price_id;
          productData['type'] = type;
          productData['amount'] = amount;
        }
        if (type == 'one_time') {
          mode = 'payment';
        } else if (type == 'recurring') {
          mode = 'subscription';
        }
        productData['mode'] = mode;
      });
      if (amount == 0 || amount == '') {
        // If amount is 0 or empty, resolve with an empty object
        resolve({});
      } else {
        resolve(productData);
      }
    });
  });
}

export function createCheckoutSession(courseId) {
  const checkoutData = {
    sku: courseId,
    mode: 'payment',
    cancel_url: window.location.href,
    success_url: window.location.href,
    username: getUserName(),
    org: getTenant(),
  };

  api.ibldmstripe.checkoutSession(checkoutData, function (response) {
    window.location.replace(response['redirect_to']);
  });
}
