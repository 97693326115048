import React, { createRef } from 'react';
import { inIframe } from '../../utils/helpers';
import {
  AppDataContext,
  getUserId,
  getUserName,
  api,
} from '@iblai/ibl-web-react-common';
import { transformProxies } from '../helpers';
import './BaseProfileView.css';

let Controller;

export default class BaseProfileView extends React.Component {
  static contextType = AppDataContext;
  static controllerPath;
  static path;
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require(BaseProfileView.controllerPath);
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code === 'MODULE_NOT_FOUND') {
        Controller = BaseProfileView;

        return Controller;
      }

      throw e;
    }
  }
  constructor(props, viewContentCls, scripts, wfPageId, wfSiteId) {
    super(props);
    this.state = {
      username: !inIframe() ? getUserName() : '',
      userId: !inIframe() ? getUserId() : -1,
      theme: !inIframe() ? 'white' : 'dark',
      inIframe: inIframe(),
      iframeAuthReady: false,
    };
    this.viewContentCls = viewContentCls;
    this.scripts = scripts;
    this.wfPageId = wfPageId;
    this.wfSiteId = wfSiteId;
    this.ref = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.ref.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries && entries.length > 0) {
          const { height } = entries[0].contentRect;
          window.parent.postMessage(
            {
              reason: 'IFRAME_DIMENSION',
              message: { height },
            },
            '*'
          );
        }
      });
      resizeObserver.observe(this.ref.current);
    }
  }
  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = this.wfPageId;
    htmlEl.dataset['wfSite'] = this.wfSiteId;

    this.scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function().call(window, script);
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
    const { appData, setAppData } = this.context;
    window.addEventListener('message', (event) => {
      if (event.data.reason === 'PROFILE_DISPLAY') {
        const username = event.data.message.username;
        const userId = event.data.message.userId;
        if (this.state.username !== username) {
          this.setState({ ...this.state, username });
          setAppData({
            ...appData,
            userInContext: username,
            userIdInContext: userId,
            iframedForProfile: true,
          });
        }
      } else if (event.data.reason === 'AUTH') {
        if (inIframe()) {
          Object.entries(event.data.message).forEach(([key, value]) => {
            localStorage.setItem(key, value);
          });
          setAppData({ ...appData, ...localStorage });
          api.initiateAxdClientsWithToken(localStorage.axd_token);
          this.setState({ ...this.state, iframeAuthReady: true });
          window.parent.postMessage(
            {
              reason: 'PROFILE_DISPLAY',
              message: { page: BaseProfileView.path },
            },
            '*'
          );
        }
      }
    });
    if (inIframe()) {
      window.parent.postMessage(
        {
          reason: 'IFRAME_READY',
          message: {},
        },
        '*'
      );
    }
  }

  render() {
    const proxies =
      BaseProfileView.Controller !== BaseProfileView
        ? transformProxies(this.props.children)
        : {};
    return (
      <AppDataContext.Consumer>
        {({ appData }) => {
          return (
            <>
              {(this.state.username || appData.userInContext) &&
                (!this.state.inIframe || this.state.iframeAuthReady) && (
                  <div
                    className="testclass"
                    ref={(node) => (this.ref.current = node)}
                  >
                    {this.state.theme === 'dark' && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `
                <style>
                  #root {
                    background: #282c34;
                  }"
                  #root * {
                    color: #fff;
                  }
                  .annotationLayer {
                   background: transparent !important;
                  }
                </style>
              `,
                        }}
                      />
                    )}
                    {(!this.state.inIframe || this.state.iframeAuthReady) && (
                      <this.viewContentCls
                        key={this.state.username}
                        username={this.state.username || appData.userInContext}
                        userId={this.state.userId || appData.userIdInContext}
                      />
                    )}
                  </div>
                )}
            </>
          );
        }}
      </AppDataContext.Consumer>
    );
  }
}
