import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  createCheckoutSession,
  enrollToCourse,
  getAllStripeProducts,
  requestAccessToCourse,
  selfEnrollToCourse,
} from '../../../hooks/utils/courseDetail';
import { useTranslation } from 'react-i18next';
import IblSpinLoader from '../../../loaders/iblSpinLoader';
import { getTenant, getTenants } from '../../../hooks/helpers';
import toast from 'react-hot-toast';

const CourseEnrollmentButton = ({
  enrollmentStatus,
  courseID,
  courseName,
  coursePrice,
  enrollmentStartDate,
  coursePlatform,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonContent, setButtonContent] = useState(null); // State to hold button content
  const [isInTenant, setIsInTenant] = useState(false);
  const [isEligible, setIsEligible] = useState(enrollmentStatus.is_eligible);
  const [canEnroll, setCanEnroll] = useState(enrollmentStatus.can_enroll);
  const [isEnrolled, setIsEnrolled] = useState(enrollmentStatus.is_enrolled);
  const [courseEnrollmentStarted, setCourseEnrollmentStarted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setIsInTenant(getTenants().findIndex((tenant) => tenant !== 'main') !== -1);
    setCourseEnrollmentStarted(new Date() - new Date(enrollmentStartDate));
  }, []);

  useEffect(() => {
    if (isLoading) {
      setButtonContent(
        <Link className="button-5 w-button" to="#">
          {/* {t('Enroll')} */}
          <IblSpinLoader color="#fff" size="25" />
        </Link>
      );
      return;
    }
    if (
      process.env.REACT_APP_ENABLE_COURSE_ELIGIBILITY_LICENSE_CHECK === 'true'
    ) {
      if (isInTenant && courseEnrollmentStarted && isEligible && isEnrolled) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (isInTenant && courseEnrollmentStarted && !isEligible) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={_requestCourseAccess}
          >
            {t('Request Access')}
          </Link>
        );
      } else if (
        isInTenant &&
        !courseEnrollmentStarted &&
        isEligible &&
        canEnroll &&
        !isEnrolled
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_selfEnrollToCourse}
          >
            {t('Enroll Now - Course Starting Soon')}
          </Link>
        );
      } else if (isInTenant && !courseEnrollmentStarted && !isEligible) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_requestCourseAccess}
          >
            {t('Request Access - Course Starting Soon')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        courseEnrollmentStarted &&
        isEligible &&
        isEnrolled
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        courseEnrollmentStarted &&
        !isEligible
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_requestCourseAccess}
          >
            {t('Request Access')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        !courseEnrollmentStarted &&
        isEligible &&
        canEnroll &&
        !isEnrolled
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_selfEnrollToCourse}
          >
            {t('Enroll Now - Course Starting Soon')}
          </Link>
        );
      } else if (
        !isInTenant &&
        coursePlatform === 'main' &&
        !courseEnrollmentStarted &&
        !isEligible
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_requestCourseAccess}
          >
            {t('Request Access - Course Starting Soon')}
          </Link>
        );
      } else if (
        isInTenant &&
        courseEnrollmentStarted &&
        isEligible &&
        !isEnrolled &&
        canEnroll
      ) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`#`}
            onClick={_selfEnrollToCourse}
          >
            {t('Enroll Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link className="button-5 w-button" to="#">
            <IblSpinLoader color="#fff" size="25" />
          </Link>
        );
      }
    } else {
      const isEnrolled = enrollmentStatus.is_enrolled;
      const invitationOnly = enrollmentStatus.invitation_only;
      if (isEnrolled) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to={`/courses/${courseID}/course`}
          >
            {t('Access Course')}
          </Link>
        );
      } else if (invitationOnly) {
        setButtonContent(
          <a className="button-5 w-button disabled" href="#">
            {t('Invitation Only')}
          </a>
        );
      } else if (coursePrice && coursePrice !== 0 && coursePrice !== 'Free') {
        setButtonContent(
          <Link
            className="button-5 w-button"
            onClick={() => createCheckoutSession(courseID)}
          >
            {t('Buy Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={handleEnrollmentBtnClick}
          >
            {t('Enroll Now')}
          </Link>
        );
      }
    }
  }, [
    isInTenant,
    isEligible,
    courseEnrollmentStarted,
    canEnroll,
    isEnrolled,
    isLoading,
  ]);

  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      const productData = await getAllStripeProducts(courseName, courseID);
      if (productData?.price_id) {
        setButtonContent(
          <Link
            className="button-5 w-button"
            onClick={() =>
              createCheckoutSession(productData?.price_id, productData?.mode)
            }
          >
            {t('Buy Now')}
          </Link>
        );
      } else {
        setButtonContent(
          <Link
            className="button-5 w-button"
            to="#"
            onClick={handleEnrollmentBtnClick}
          >
            {t('Enroll Now')}
          </Link>
        );
      }
    } catch (error) {
      console.error('Error fetching Stripe products:', error);
      setButtonContent(null); // Set button content to null in case of error
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_IBL_ENABLE_STRIPE === 'true') {
      fetchData();
    } else {
      setButtonContent(
        <Link
          className="button-5 w-button"
          to="#"
          onClick={handleEnrollmentBtnClick}
        >
          Enroll
        </Link>
      );
    }
    return () => {};
  }, [courseName, courseID]); // Dependencies for useEffect

  const handleEnrollmentBtnClick = () => {
    enrollToCourse(enrollToCourseCallback, courseID);
  };

  const _requestCourseAccess = () => {
    setIsLoading(true);
    requestAccessToCourse(
      requestAccessToCourseCallback,
      requestAccessToCourseErrorCallback,
      courseID,
      getTenant()
    );
  };

  const _selfEnrollToCourse = () => {
    setIsLoading(true);
    selfEnrollToCourse(
      selfEnrollToCourseCallback,
      selfEnrollToCourseErrorCallback,
      courseID
    );
  };

  const selfEnrollToCourseCallback = () => {
    setIsLoading(false);
    navigate(`/courses/${courseID}/course`);
  };

  const selfEnrollToCourseErrorCallback = () => {
    setIsLoading(false);
  };

  const requestAccessToCourseCallback = () => {
    setIsLoading(false);
    toast.success(
      t(
        "Request Access to Course Successful. We'll get back to you soon regarding this."
      )
    );
  };

  const requestAccessToCourseErrorCallback = (error) => {
    setIsLoading(false);
    if (error.responseJSON.error_text === 'request_exists') {
      toast.error(
        t(
          'You have requested for access to this course already. We are processing your request for access to this course.'
        )
      );
    }
  };

  const enrollToCourseCallback = (data) => {
    if (data && data.created) {
      navigate(`/courses/${courseID}/course`);
    } else {
      toast.error(t('Course Enrollment Failed, Something went wrong'));
    }
  };

  return buttonContent;
};

export default CourseEnrollmentButton;
