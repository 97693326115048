import React, { useEffect, useState } from 'react';
import * as RangeSliderModule from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { getSkillRatingDescription } from '../../hooks/helpers';

const SkillRating = ({
  selectedSkills,
  handleSkillToggle,
  setSelectedSkills,
  roleMin,
}) => {
  const RangeSliderComponent = RangeSliderModule?.default
    ? RangeSliderModule.default
    : RangeSliderModule;

  const initialRatings = selectedSkills.reduce((acc, skill) => {
    acc[skill.data.id] = [0, 0]; // Default range slider value for each skill
    return acc;
  }, {});

  const [ratings, setRatings] = useState(initialRatings);
  const [selectedSkillDescriptions, setSelectedSkillDescriptions] = useState(
    selectedSkills.reduce((acc, skill) => {
      acc[skill.data.id] = getSkillRatingDescription(0);
      return acc;
    }, {})
  );

  useEffect(() => {
    const newRatings = selectedSkills.reduce((acc, skill) => {
      acc[skill.data.id] = ratings[skill.data.id] || [0, 0];
      return acc;
    }, {});
    setRatings(newRatings);

    const newDescriptions = selectedSkills.reduce((acc, skill) => {
      acc[skill.data.id] = getSkillRatingDescription(
        ratings[skill.data.id]?.[1] || 0
      );
      return acc;
    }, {});
    setSelectedSkillDescriptions(newDescriptions);
  }, [selectedSkills]);

  const handleSliderChange = (skillId, sliderValue) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [skillId]: sliderValue,
    }));

    setSelectedSkillDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [skillId]: getSkillRatingDescription(sliderValue[1]),
    }));

    // Update the selectedSkills with the new rating
    setSelectedSkills((prevSelectedSkills) =>
      prevSelectedSkills.map((skill) =>
        skill.data.id === skillId
          ? {
              ...skill,
              rating: sliderValue[1], // Add or update the rating in the selectedSkills
            }
          : skill
      )
    );
  };

  return (
    <>
      <div className="div-block-84 sk">
        <div className="text-block-150 rate">
          Now, Self Rate at Least {roleMin} Skill(s)
        </div>
      </div>
      <div className="ski_table">
        <div className="div-block-253">
          <div className="skh">
            <div className="c _0">
              <img
                src="images/question-mark-on-a-circular-black-background_1question-mark-on-a-circular-black-background.png"
                loading="lazy"
                alt=""
                className="image-95"
              />
            </div>
            <div className="c left s">
              <div>Skill</div>
            </div>
            <div className="c _40 left">
              <div>Self Rating</div>
            </div>
            <div className="c left _10">
              <div>Source</div>
            </div>
            <div className="c">
              <div>Remove</div>
            </div>
          </div>
          {selectedSkills.map((skill) => (
            <div className="skr" key={skill.data.id}>
              <div className="c _0">
                <img
                  src="images/star_2star.png"
                  loading="lazy"
                  alt=""
                  className="image-95 l"
                />
              </div>
              <div className="c left s">
                <div>{skill.data.name}</div>
              </div>
              <div className="c _40 left">
                <div className="level-sld">
                  <div className="div-block-87">
                    <div className="step_n _0">0</div>
                    <div className="step_n l4">1</div>
                    <div className="step_n l4">2</div>
                    <div className="step_n _2">3</div>
                    <div className="step_n">4</div>
                    <div className="step_n">5</div>
                  </div>
                  <div className="fs-rangeslider_form w-form">
                    <form
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      method="get"
                      data-wf-page-id="64788bd6db640a0cbeade161"
                      data-wf-element-id="f3cfb3f2-bdc0-10ae-f311-be7ac7d4c757"
                    >
                      {ratings[skill.data.id] && (
                        <RangeSliderComponent
                          id={`range-slider-${skill.data.id}`}
                          className="single-thumb"
                          defaultValue={[0, 50]}
                          thumbsDisabled={[true, false]}
                          rangeSlideDisabled={false}
                          onInput={(sliderValue) =>
                            handleSliderChange(skill.data.id, sliderValue)
                          }
                          value={ratings[skill.data.id]}
                          range={false}
                          min={0}
                          max={5}
                          step={1}
                        />
                      )}
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="div-block-89">
                    <div className="level-text">
                      {selectedSkillDescriptions[skill.data.id]}
                    </div>
                  </div>
                </div>
                <div className="div-block-236">
                  <div id={`i-${skill.data.id}`} className="text-block-151">
                    {ratings[skill.data.id] !== undefined
                      ? ratings[skill.data.id][1]
                      : ''}
                  </div>
                </div>
              </div>
              <div className="c left _10">
                <div>Added Skill</div>
              </div>
              <div className="c" style={{ cursor: 'pointer' }}>
                <img
                  src="/images/close_1close.png"
                  loading="lazy"
                  alt=""
                  className="image-94"
                  onClick={() => handleSkillToggle(skill)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SkillRating;
