import {
  isRouteErrorResponse,
  useParams,
  useRouteError,
} from 'react-router-dom';
import {
  ActivityView,
  ArticleView,
  BrowseView,
  CoursesView,
  CourseView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RecommendedView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import {
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  getCurrentTenantFromCookie,
  CompleteLogin,
  sentryCreateBrowserRouter,
  getUserName,
  api,
} from '@iblai/ibl-web-react-common';
import CourseIframeCourseContentView from './views/content/CourseIframeCourseContentView/CourseIframeCourseContentView';
import CourseIframeCourseTabView from './views/content/CourseIframeCourseTabView/CourseIframeCourseTabView';
import { inIframe } from './utils/helpers';
import StaticClassView from './views/StaticClassView';
import { isStaticPagesEnabled } from './hooks/helpers';
import IntroductoryView from './views/IntroductoryView/IntroductoryView';
import { CourseUnitIframe } from './views/content/CourseUnitIframe/CourseUnitIframe';
import { authGuard } from './route-guards/auth-guard';

const STATIC_PAGES_ENABLED = isStaticPagesEnabled();

const ErrorPage = () => {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        localStorage.clear();
        localStorage.setItem('redirect-path', window.location.pathname);
        window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
      }
    }
  }
};

const CourseIframeCourseTabWrapper = () => {
  const params = useParams();
  let tab = params.tab;
  if (params.hasOwnProperty('*')) {
    tab += `/${params['*']}`;
  }

  return <CourseIframeCourseTabView tab={tab} key={tab} />;
};

const route = (appData, setAppData) => {
  const routes = [
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        ...(STATIC_PAGES_ENABLED
          ? [
              {
                path: '',
                element: <StaticClassView />,
                loader: authGuard(false, false, appData, setAppData),
              },
              {
                path: 'public',
                element: <StaticClassView />,
                loader: authGuard(false, false, appData, setAppData),
                children: [
                  {
                    path: ':publicPath',
                    element: <StaticClassView />,
                  },
                ],
              },
            ]
          : []),
        {
          path: '',
          element: <DashboardView />,
          children: [
            {
              path: `${STATIC_PAGES_ENABLED ? `${process.env.REACT_APP_IBL_STATIC_PAGES_ENABLED_DASHBOARD_PATH}` : ''}`,
              element: <IndexView />,
              loader: authGuard(false, true, appData, setAppData),
            },
            {
              path: 'profile',
              element: <ProfileView />,
              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                  loader: authGuard(false, false, appData, setAppData),
                },
                {
                  path: 'pathways/:pathwayID/:username',
                  element: <PathwayView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                  loader: authGuard(false, true, appData, setAppData),
                },
              ],
            },
            {
              path: 'courses/:courseID',
              element: <CourseView />,
              loader: authGuard(false, false, appData, setAppData),
            },
            {
              path: 'programs/:programID',
              element: <ProgramView />,
              loader: authGuard(false, true, appData, setAppData),
            },
            {
              path: 'pathways/:pathwayID/:username',
              element: <PathwayView />,
              loader: authGuard(false, true, appData, setAppData),
            },
            {
              path: 'courses/',
              element: <ArticleView />,
              loader: authGuard(false, true, appData, setAppData),

              children: [
                {
                  path: ':courseID',
                  element: <CourseIframeCourseContentView />,
                  children: [
                    {
                      path: 'course',
                      element: <CourseUnitIframe />,
                    },
                    {
                      path: ':tab/*', // Matches any subpath not matched by above
                      element: <CourseIframeCourseTabWrapper />,
                    },
                  ],
                },
              ],
            },
            process.env.REACT_APP_HIDE_RECOMMENDED_TAB !== 'true'
              ? {
                  path: 'recommended',
                  element: <RecommendedView />,
                  loader: authGuard(false, true, appData, setAppData),
                }
              : {},
            {
              path: 'discover',
              element: <BrowseView />,
              loader: authGuard(false, false, appData, setAppData),
            },
          ],
        },
        ...(process.env.REACT_APP_IBL_APP_ENABLE_START_ROLE === 'true'
          ? [
              {
                path: 'start',
                element: <IntroductoryView />,
              },
            ]
          : []),
        {
          path: 'certificates/:credentialID',
          element: <CredentialView />,
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authGuard(false, false, appData, setAppData),
        },
        {
          path: 'logout',
          element: (
            <Logout
              redirectTo={
                STATIC_PAGES_ENABLED
                  ? window.location.origin
                  : `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`
              }
            />
          ),
          loader: authGuard(false, false, appData, setAppData),
        },
      ],
    },
  ];

  return sentryCreateBrowserRouter(routes);
};

export default route;
